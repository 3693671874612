html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  height: 100%;
}
.margin-auto {
  margin: 0 auto;
}
.warranty-form {
  max-width: 300px !important;
  margin: 0 auto;
}
.content-wrapper {
  min-height: calc(100% - 381px);
}
.product-thumbnail{
  width: 100%;
}
.is-divider {
  height: 3px;
  display: block;
  background-color: rgba(0,0,0,0.1);
  margin: 1em 0 1em;
  width: 100%;
  max-width: 30px;
}

.box-wrapper {
  border: 1px solid #eeeeee;
  border-radius: 3px;
}
.box-label {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 5px;
  color: #444;
}
.text-link {
  color: #007bff;
}
.text-link:hover {
  cursor: pointer;
  color: #0056b3;
  text-decoration: underline;
}

.hover_option_customer:hover {
  background-color: #ccc;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.loading-spinner {
  display: inline-block;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}